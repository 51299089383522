<template>
    <div class="card login">
        <div class="image-container logo">
            <img src="./../assets/dir/img/logo.png" alt="Mikari Logo">
        </div>
        <p><b>REGISTREER UW TELEFOONNUMMER</b></p>
        <p>Uw telefoonnummer is uw Vizion Account. Registreer uw telefoonnummer en kies in welke valuta u wilt spelen.</p>

        <button class="button" @click="next()">REGISTREER NU</button>
        <button class="button" @click="back()">TERUG</button>
    </div>
</template>

<script>

export default {
    methods: {
        next() {
            this.$router.push('/register');
        },
        back() {
            window.location = 'https://mikari.world';
        }
    }
}
</script>