<template>
    <div class="card login">
        <div class="image-container logo">
            <img src="./../assets/dir/img/logo.png" alt="Mikari Logo">
        </div>
        <!-- <div class="title-container">
            <h1 class="title">Agent Login</h1>
        </div> -->
        <p><b>Registratie</b></p>
        <div class="form">
            <div class="input-container">
                <label for="number">Enter your verification code</label>
                <input type="number" name="verification_code" id="verification_code" v-model="verification_code" placeholder="">
            </div>
        </div>

        <button class="button" @click="next()">Verify</button>
        <button class="button" @click="back()">TERUG</button>

        <div class="space-2"></div>
        <p><small>IF YOU NEED SUPPORT, PLEASE SEND AN EMAIL TO HELP@VIZION.SR OF CHAT WITH US VIA VIZION.SR</small></p>
    </div>
</template>

<script>
import axios from 'axios'
import { config } from './../config'

export default {
    data() {
        return {
            verification_code: null
        }
    },

    methods: {
        next() {
            this.$store.dispatch('app/asyncUpdateVerificationCode', {
                verification_code: this.verification_code
            });

            if ( this.verification_code == '' || this.verification_code == null ) {
                alert('Fill in verification code');
                return;
            }

            var that = this

            axios.post(`${config.host}/vizion/create`, {
                "currency": that.$store.getters["app/currency"],
                "country_code": that.$store.getters["app/countryCode"],
                "mobile": that.$store.getters["app/mobile"],
            })
            .then(function (response) {
                var { data } = response;
                if ( data.success === true ) {
                    that.$router.push('/success')
                } else {
                    alert(data.message);
                }
            })
            .catch(function () {
                alert("Error occurred")
                // console.log(error);
            });

            
        },

        back() {
            this.$router.push('/selectcurrency')
        }
    }
}
</script>