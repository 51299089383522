<template>
    <div class="card login">
        <div class="image-container logo">
            <img src="./../assets/dir/img/logo.png" alt="Mikari Logo">
        </div>
        <p><b>KIES UW VALUTA</b></p>
        <p>Bij Vizion mag je in valuta spelen. U dient dan wel uw tegoed in de gekozen valuta te betalen. U wordt ook uitbetaald in de gekozen valuta.</p>

        <button class="button" @click="next()">KIES VALUTA NU</button>
        <button class="button" @click="back()">TERUG</button>
    </div>
</template>

<script>
export default {
    methods: {
        next() {
            this.$router.push('/selectcurrency')
        },

        back() {
            this.$router.push('/register')
        }
    }
}
</script>