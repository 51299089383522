import Vue from 'vue'
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

import App from './App.vue'
import router from './router'
import store from './store/index';

import './assets/lib/bootstrap/css/bootstrap.min.css'
import './assets/lib/materialdesignicons/css/materialdesignicons.min.css'
import './assets/dir/css/style.css'

Vue.config.productionTip = false

// Sentry.init({
//   Vue,
//   dsn: "https://3f1d7886410a40668cb4e6ff963aba4e@sentry.e-gostudio.com/12",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

new Vue({
  router,
  store,
  render: h => h(App),

  // Watch for route changes to do access token validation
  watch: {
    $route (){
        validateStart();
    }
  } 
}).$mount('#app')

/**
 * If there is no access token,
 * go to login screen.
 */
function validateStart() {
  if ( store.getters["app/start"] === true && router.history._startLocation != '/preregister' ) {
    router.push('/preregister')

    store.dispatch('app/asyncUpdateStart', {
      start: false,
    });
  }
}
validateStart();
