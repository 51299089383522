<template>
    <div class="card login">
        <div class="image-container logo">
            <img src="./../assets/dir/img/logo.png" alt="Mikari Logo">
        </div>
        <!-- <div class="title-container">
            <h1 class="title">Agent Login</h1>
        </div> -->
        <p><b>Registratie</b></p>
        <div class="form">
            <div class="input-container">
                <label for="number">Country code</label>
                <input type="number" name="country_code" id="country_code" v-model="country_code" placeholder="597">
            </div>
            <div class="input-container">
                <label for="number">Mobile number</label>
                <input type="number" name="mobile" id="mobile" v-model="mobile" placeholder="12345678">
            </div>
        </div>

        <button class="button" @click="next()">VERIFY</button>
        <button class="button" @click="back()">TERUG</button>
    </div>
</template>

<script>
import axios from 'axios'
import { config } from './../config'

export default {
    data() {
        return {
            country_code: null,
            mobile: null,
        }
    },

    methods: {
        next() {
            this.$store.dispatch('app/asyncUpdateNumber', {
                country_code: this.country_code,
                mobile: this.mobile,
            });

            if ( this.country_code == '' || this.mobile == '' || this.country_code == null || this.mobile == null ) {
                alert('Fill in mobile number');
                return;
            }

            var that = this

            axios.post(`${config.host}/vizion/check_number`, {
                "country_code": this.country_code,
                "mobile": this.mobile,
            })
            .then(function (response) {
                var { data } = response;
                if ( data.success === true ) {
                    if ( data.validated != null ) {
                        that.$router.push('/success')
                    } else {
                        that.$router.push('/preselectcurrency')
                    }
                }
                else {
                    that.$router.push('/preselectcurrency')
                }
            })
            .catch(function () {
                alert("Error occurred")
                // console.log(error);
            });
        },

        back() {
            this.$router.push('/preregister')
        }
    }
}
</script>