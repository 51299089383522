import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from './pages/Register'
import Preregister from './pages/Preregister'
import PreSelectCurrency from './pages/PreSelectCurrency'
import SelectCurrency from './pages/SelectCurrency'
import Verification from './pages/Verification'
import Success from './pages/Success'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: { name: 'Preregister' } },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/preregister',
    name: 'Preregister',
    component: Preregister
  },
  {
    path: '/preselectcurrency',
    name: 'PreSelectCurrency',
    component: PreSelectCurrency
  },
  {
    path: '/selectcurrency',
    name: 'SelectCurrency',
    component: SelectCurrency
  },
  {
    path: '/verification',
    name: 'Verification',
    component: Verification
  },
  {
    path: '/success',
    name: 'Success',
    component: Success
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
