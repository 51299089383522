<template>
    <div class="card login">
        <div class="image-container logo">
            <img src="./../assets/dir/img/logo.png" alt="Mikari Logo">
        </div>
        <p><b>PAY, PLAY AND PAY OUT</b></p>
        
        <table>
            <tr>
                <td></td>
                <td><b>CURRENCY</b></td>
            </tr>
            <tr>
                <td><input type="radio" name="currency" v-model="currency" value="eur"></td>
                <td><label>EURO</label></td>
            </tr>
            <tr>
                <td><input type="radio" name="currency" v-model="currency" value="usd"></td>
                <td><label>US DOLLAR</label></td>
            </tr>
            <tr>
                <td><input type="radio" name="currency" v-model="currency" value="srd"></td>
                <td><label>SURINAMESE DOLLAR</label></td>
            </tr>
        </table>

        <button class="button" @click="next()">VERIFY</button>
        <button class="button" @click="back()">TERUG</button>
    </div>
</template>

<script>
import axios from 'axios'
import { config } from './../config'

export default {
    data() {
        return {
            currency: 'srd'
        }
    },

    methods: {
        next() {
            this.$store.dispatch('app/asyncUpdateCurrency', {
                currency: this.currency
            });

            var that = this

            axios.post(`${config.host}/vizion/create`, {
                "currency": that.$store.getters["app/currency"],
                "country_code": this.country_code,
                "mobile": this.number,
            })
            .then(function (response) {
                var { data } = response;
                if ( data.success === true ) {
                    if ( data.validated === true ) {
                        that.$router.push('/success')
                    } else {
                        that.$router.push('/verification')
                    }
                }
                else {
                    that.$router.push('/verification')
                }
            })
            .catch(function () {
                alert("Error occurred")
                // console.log(error);
            });
        },

        back() {
            this.$router.push('/preselectcurrency')
        }
    }
}
</script>