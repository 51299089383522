<template>
  <div id="app" class="content-wrapper login-card-container">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'Vizion',
}
</script>
