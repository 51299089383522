const state = {
    start: true,
    country_code: null,
    mobile: null,
    currency: null,
    verification_code: null
}

const mutations = {
    updateStart: (state, payload) => state.start = payload.start,
    updateCurrency: (state, payload) => state.currency = payload.currency,
    updateVerificationCode: (state, payload) => state.verification_code = payload.verification_code,
    updateNumber: (state, payload) => {
        state.country_code = payload.country_code;
        state.mobile = payload.mobile;
    },

    emptyOrdersData: (state) => state.orders_data = null,
}

const actions = {
    asyncUpdateStart ( {commit}, payload ) {
        commit('updateStart', payload)
    },
    asyncUpdateCurrency ( {commit}, payload ) {
        commit('updateCurrency', payload)
    },
    asyncUpdateVerificationCode ( {commit}, payload ) {
        commit('updateVerificationCode', payload)
    },
    asyncUpdateNumber ( {commit}, payload ) {
        commit('updateNumber', payload)
    },
    asyncEmptyOrdersData ( {commit} ) {
        commit('emptyOrdersData')
    },
}

const getters = {
    start: state => {
        return state.start
    },
    countryCode: state => {
        return state.country_code
    },
    mobile: state => {
        return state.mobile
    },
    currency: state => {
        return state.currency
    },
    verificationCode: state => {
        return state.verification_code
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
