<template>
    <div class="card login">
        <div class="image-container logo">
            <img src="./../assets/dir/img/logo.png" alt="Mikari Logo">
        </div>
        <p><b>CONGRATULATION AND START PLAYING OUR GREAT GAMES AND WIN!</b></p>
        <p>YOU HAVE RECEIVED ANOTHER SMS WITH YOUR USERNAME AND PASSWORD.</p>

        <button class="button" @click="go()">GO TO VIZION</button>

        <div class="space-2"></div>
        <p><small>IF YOU NEED SUPPORT, PLEASE SEND AN EMAIL TO HELP@VIZION.SR OF CHAT WITH US VIA VIZION.SR</small></p>
    </div>
</template>

<script>
export default {
    methods: {
        go() {
            window.location = 'https://gamble.vizion.sr';
        }
    }
}
</script>